<div class="redirect-cmp" *ngIf="redirecting">
  <app-redirect
    (onRedirect)="onRedirectAfterAffirm()"
  ></app-redirect>
</div>
<div class="container-fluid" *ngIf="!redirecting">
  <div class="row sub-container">
    <div class="mobile-header">
      <div class=" mobile-header-holder">
        <div class=" mobile-header-left" *ngIf="programData.length !== 0">

          @if(isForTeens){
            <img class="isforteens-logo" src="assets/images/logo-jr.png">
          } @else {
            <img src="assets/images/bordered-logo.png">
          }
        </div>
        <div class=" mobile-header-right">
          <p>Secure Checkout  </p>
        </div>

      </div>
     <!-- <p class="mobile-second-row"><span> Program</span><br>{{programData?.lmsProgramName}}</p>
      <p></p>-->

    </div>

    <div class="left-overlay">
    <div class=" left">
      <!--  <img src="assets/images/logo.svg">-->
      <div class=" left-main" >
        <div class="col-12 title-holder1" style="margin-top: 100px;">
          <p class="checkout-title" >

            @if(isForTeens){
              <img  style="margin-top: -4px; margin-left: -9px; margin-right: 5px;" class="isforteens-logo" src="assets/images/logo-jr.png">Secure checkout
              <img src="assets/images/lock.svg" class="icon-size">

            } @else {
              <img width="300px" src="assets/images/bordered-logo.png"><br>

            }
          </p>
        </div>
        <span style="padding-left: 10px;">Secure Checkout</span>
        <div class="program-details" style="">
          <span style="padding-left: 10px;">Program</span>
          <p class="course-name"><a href="{{programData.productLink}}" target="_blank"> {{programData?.lmsProgramName}} </a></p>
        </div>
      </div>
      <div class=" footer-row">
        <div class="details-footer" >
          @if(isForTeens){

            <a href="https://cydeojr.com" target="_blank"><img style="max-width: 90px;" src="assets/images/logo-jr.png"></a>
          }@else {
          <a href="https://cydeo.com" target="_blank"><img style="max-height: 30px;" src="assets/images/logo.svg"></a>
          }
          <a href="https://cydeo.com/terms-conditions/" target="_blank">Terms and conditions</a>
          <a href="https://cydeo.com/privacy-policy/" target="_blank">Privacy Policy</a>
          <a href="https://cydeo.com/disclaimer/" target="_blank">Disclaimer</a>
          <a href="https://cydeo.com/cookie-policy/" target="_blank">Cookie Policy</a>
        </div>
      </div>

    </div>
    </div>
    <div class="col right">
      <div class="mobile-image" *ngIf="step === 1">
        <div class="mobile-image-overlay">
        <div class="program-details" style="">
          <span style="padding-left: 10px; color: #fff;">Program</span>
          <p class="course-name"><a href="{{programData.productLink}}" target="_blank"> {{programData?.lmsProgramName}} </a></p>
        </div>
      </div>
    </div>
      <div class="stepper">
        <div class="stepper-horizontal d-flex " id="stepper1">
          <div class="step " style="padding-left: 0;" *ngIf="step === 1 || 2" [ngClass]="step > 1 ? 'done' : 'editing'">
            <div class="step-circle"><span>1</span></div>
            <div class="step-title">Start Application</div>
            <div class="step-bar-left" style="width: calc(100% - 1rem); margin-left: 2px;"></div>
            <div class="step-bar-right" style="margin-left: 2px;     width: calc(100% - 1rem);"></div>
          </div>
          <div class="step" *ngIf="step === 1 ||   2 || 3"
               [ngClass]="{'done': step > 2, 'editing' : step === 2, '' : step < 2 }">
            <div class="step-circle"><span>2</span></div>
            <div class="step-title">Payment plan</div>
            <div class="step-bar-left"></div>
            <div class="step-bar-right"></div>
          </div>
          <div class="step" *ngIf="step === 2 || 3 || 4"
               [ngClass]="{'done': step > 3, 'editing' : step === 3, '' : step < 3 }">
            <div class="step-circle"><span>3</span></div>
            <div class="step-title">Review</div>
            <div class="step-bar-left"></div>
            <div class="step-bar-right"></div>
          </div>
        </div>

      </div>
      <div class="step1" [ngClass]="step === 1 ? 'show-step' : 'hide-step'">
        <div class="form details-form">
          <form class="info-form" [formGroup]="form" (ngSubmit)="checkCaptcha()">
            <div class="row form-details"  *ngIf="programData.length !== 0">
              <div class="col-10 col-xs-11 form-details-1 ">
                <div class=" info-holder">
                  <div class="row">
                    <p class="program-title">{{programData?.lmsProgramName}}</p>
                  </div>
                  <div class="row" *ngIf="programData.productType === 'ENROLLMENT'">
                    <p style=" padding-left: 0; font-size: 18px; margin-bottom: 10px;">Original Tuition $13500</p>
                    <p style="padding-left: 0; font-size: 18px; margin-bottom: 10px;">Limited Time Offer <span class="light-green" style="font-size: 20px;"> $9000</span></p>
                  </div>
                </div>
                <div class=" info-holder right-info-holder">
                  <div class="last-row">
                    <div  class="col-sm " style="margin-bottom: 0px;">
                    @if(programData.productType === 'ENROLLMENT'){

                    } @else {
                      <b class="light-green-text">${{programData?.fullPrice }}&nbsp;</b>
                       }
                    </div>
                  </div>
                  <div class="row">
                    @if(programData.productType === 'ENROLLMENT'){
                      <p class="upfront-text">Offer available until {{enrollmentEndDate}}</p>
                    } @else {
                      @if(programData?.discountedPrice && programData?.discountAmount > 0){
                        <p *ngIf="!isSdFt008" class="upfront-text">Save ${{programData?.discountAmount}} when you pay upfront</p>
                      } @else if (hasCoupon) {
                        <p *ngIf="!isSocCourse && !isSoc005"  class="text-apple">Save more with coupons</p>
                      }
                    }
                    <p class="flexible-payments info last-row">Flexible payment plans available</p>
                  </div>

                  <div class="row">
                    <div class="col-sm info">Start Date:
                      @if(programData?.startDate){
                      <span class="info-value">{{programData?.startDate | date: 'mediumDate'}}</span>
                        } @else {
                        <span class="info-value">Any time</span>
                      }
                    </div>
                  </div>
                  <div class="row" *ngIf="isSocCourse || isSoc005">
                    <div class="col-sm info">Program End Date
                      <span class="info-value">{{isSocCourse ? 'June 11, 2024' : 'July 28 2024' }}</span>
                    </div>
                  </div>
                  <div class="row" *ngIf="programData?.refundDate">
                    <div class="col-sm info"><span class="light-green">100% </span>refund policy until
                      <span class="info-value">{{programData?.refundDate | date: 'mediumDate'}}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <br>

            <div class="row form-details">

              <div class="col-10 personal-details">
                <p><b class="primary-color todo" style="color: #fff;">Enter personal details</b></p>
                <br>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>First Name</mat-label>
                        <input formControlName="firstName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Last Name</mat-label>
                        <input formControlName="lastName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Email Address</mat-label>
                        <input type="email" formControlName="email" (blur)="getPaymentPlansForUser($event)" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                    <div class="col-sm">
                      <div class="form-group">
                        <mat-form-field class="w-100" >
                          <mat-label>Phone</mat-label>
                          <input appOnlyNumbers formControlName="phoneNumber" matInput>
                        </mat-form-field>
                      </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>How did you hear about us?</mat-label>
                        <mat-select #refSelectId (selectionChange)="onSelectionChange($event)"  formControlName="referralSource">
                          <mat-option selected>Select One</mat-option>
                          <mat-option value="email">Email</mat-option>
                          <mat-option value="facebook">Facebook</mat-option>
                          <mat-option value="google">Google</mat-option>
                          <mat-option value="instagram">Instagram</mat-option>
                          <mat-option value="linkedin">LinkedIN</mat-option>
                          <mat-option value="twitter">Twitter</mat-option>
                          <mat-option value="referred">Referred by someone</mat-option>
                          <mat-option value="other">Other</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-sm">
                    <div class="form-group" *ngIf="showReferrerName">
                      <mat-form-field class="w-100" >
                        <mat-label>Referrer full name</mat-label>
                        <input type="email" formControlName="referrerFullName" required matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
            <!--    <div class="row">
                  <div class="form-check chck-form">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                    <label class="form-check-label promotion-label promotions-check" for="defaultCheck1">
                      Keep me up to date on latest promotions<br> <span class="privacy-checkbox">Your data is save with
                        us. Read our <a href="https://cydeo.com/privacy-policy/" target="_blank">Privacy Policy.</a></span>
                    </label>
                  </div>
                </div>-->
              </div>
              <!-- Parent details -->
              <div *ngIf="isForTeens" class="col-9 personal-details" formGroupName="parent">
                <p><b class="primary-color todo" style font-size="16">Enter parent details</b></p>
                <br>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>First Name</mat-label>
                        <input formControlName="firstName" [required]="isForTeens" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Last Name</mat-label>
                        <input formControlName="lastName" [required]="isForTeens" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Email Address</mat-label>
                        <input type="email" formControlName="email" [required]="isForTeens" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100">
                        <mat-label>Phone</mat-label>
                        <input  appOnlyNumbers formControlName="phoneNumber" [required]="isForTeens" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class=" next-button" type="submit" [disabled]="nextButtonDisabled">
            @if(nextButtonDisabled){
              <div class="button-spinner">
                <mat-progress-spinner
                  class="example-margin"
                  [diameter]="20"
                  [mode]="'indeterminate'">
                </mat-progress-spinner>
              </div>
            } @else {
              Next
            }
            </button>
          </form>

          <div class="right-footer">
            <div class="col-9">
              <p class="footer-text">{{'Need help? Contact us at enrollment@cydeo.com '}} Phone: 571-640-9191 <br>Schedule a call: https://info.cydeo.com/enrollment-consultation</p>
            </div>
          </div>

        </div>
      </div>
      <div class="step2" [ngClass]="step === 2 ? 'show-step' : 'hide-step'">
        <div class="payment-plan">
          <p class="primary-color todo" style ="font-size:16px">Choose a payment plan
            <span *ngIf="getProgramNameFromUrl() === 'jd-al007' && reviewData?.receipt?.paymentSummary?.alumniDiscount" style="color: lightgreen">Exclusive Alumni Discount</span></p>
          <mat-accordion>
            <div class="payment-plan-item-holder"  *ngFor="let item of paymentPlanItems">

              <mat-expansion-panel   hideToggle>
                <mat-expansion-panel-header  [ngClass]="paymentPlanIdSelected == item.priceId ? 'plan-selected' : ''" (click)="itemSelected(item)">
                  <mat-panel-title class="payment-panel-title">
                    <div class="panel-text-holder">
                       @if(programData.productType === 'ENROLLMENT' && item.type === 'recurring'){
                         @if(!item?.hasAgreement){
                           <span class="payment-type" style="font-size: 16px; font-weight: 500; margin-bottom: 8px;">Tuition Advantage <b><i>Most Popular!</i></b> </span>
                         } @else {
                           <span class="payment-type" style="font-size: 16px; font-weight: 500; margin-bottom: 8px;">{{item.title }} </span>
                         }

                         @if(item?.hasAgreement){
                           <span class="discount-price payment-type">  ${{ item.receipt.installmentSummary.fullPrice}}<span> total</span></span>
                         } @else {
                         <span class="discount-price payment-type">  ${{ item.receipt.installmentSummary.installmentPrice}}<span> per month</span></span>
                         }
                         @if(item?.hasAgreement){
                           <span>Pay T1 <span class="discount-price">${{item.receipt.installmentSummary?.basePrice}}</span> now over {{item?.installments}} installments. Pay T2 later</span>
                         }
                           @else {
                           <span class="payment-type"> {{item?.installments}} Installments(<span class="discount-price">
                           ${{item.receipt.installmentSummary?.discountedPrice ? item.receipt.installmentSummary?.discountedPrice : item.receipt.installmentSummary?.basePrice}}</span> total) </span>
                         }
                       }

                    @if(programData.productType !== 'ENROLLMENT' && item.type !== 'recurring'){
                    <span class="payment-type" style="font-size: 16px;">  {{ item.type === 'recurring' ?  item.installments + ' Installments' : 'Upfront' }}</span>
                    }
                      @if(programData.productType === 'ENROLLMENT' && item.type !== 'recurring'){
                        <span class="payment-type" style="font-size: 16px;"> Upfront</span>
                      }
                      @if(item.type === 'recurring' &&  programData.productType !== 'ENROLLMENT' ) {
                        <span class="payment-type" style="font-size: 16px;">  {{ item.type === 'recurring' ?  item.installments + ' Installments' : 'Upfront' }}</span>
                      }
                  <div class="title-second-row">
                  <div>
                      @if(item.type === 'recurring' && programData.productType !== 'ENROLLMENT') {
                      <span class="discount-price payment-type">  ${{ item.receipt.installmentSummary?.installmentPrice}}<span> per month</span></span>
                      }
                      @if(item.type !== 'recurring') {
                        <div class="price-holder">

                          <span class="base-price" *ngIf="item.receipt?.paymentSummary?.oneTimeDiscount && item.receipt.paymentSummary.oneTimeDiscount > 0">${{item.receipt.paymentSummary.basePrice}}</span>
                          <span class="discount-price">${{item.receipt.paymentSummary.subTotal}} <span> pay once</span></span>
                        </div>
                      }
                  </div>
                  </div>
                    </div>
                    <div class="offer-badges">
                      <mat-chip-set>
                        <mat-chip class="discount-badge" *ngIf="item.receipt.paymentSummary?.oneTimeDiscount && !item.receipt.paymentSummary?.alumniDiscount">
                      <span class="chip-content">    <span class="material-symbols-outlined light-icon">electric_bolt</span> ${{ item.receipt.paymentSummary.oneTimeDiscount }} savings
                      </span>
                        </mat-chip>
                        <mat-chip class="discount-badge" *ngIf="item.receipt.paymentSummary?.alumniDiscount">
                      <span class="chip-content">    <span class="material-symbols-outlined light-icon">electric_bolt</span> ${{ item.receipt.paymentSummary.alumniDiscount }} savings
                      </span>
                        </mat-chip>
                        <mat-chip class="discount-badge" *ngIf="item.receipt?.installmentSummary?.planDiscount">
                      <span class="chip-content">    <span class="material-symbols-outlined light-icon">electric_bolt</span> ${{ item.receipt?.installmentSummary?.planDiscount }} savings
                      </span>
                        </mat-chip>
                        <mat-chip *ngIf="coupons.length > 0 && hasCouponApplied(item)" class="discount-badge" >
                          <span class="chip-content"><span class="material-symbols-outlined">shoppingmode</span>${{couponAmount}} Coupon savings</span>
                        </mat-chip>
                      </mat-chip-set>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content-payment">

                  @if(item.type === 'one_time'){
                    <div class="content-panel-item">
                      <span>Base tuition</span>
                      <span class="price">${{reviewData?.receipt?.paymentSummary?.basePrice}}</span>
                    </div>
                    <div *ngIf="reviewData?.receipt?.paymentSummary?.oneTimeDiscount && !item.receipt.paymentSummary?.alumniDiscount" class="content-panel-item">
                      <span>Upfront savings</span>
                      <span class="price">- ${{ reviewData?.receipt?.paymentSummary?.oneTimeDiscount }} </span>
                    </div>
                    <div *ngIf="reviewData?.receipt?.paymentSummary?.alumniDiscount" class="content-panel-item">
                      <span>Alumni savings</span>
                      <span class="price">- ${{ reviewData?.receipt?.paymentSummary?.alumniDiscount }} </span>
                    </div>
                    <button class="link-button" *ngIf="!reviewData?.receipt?.paymentSummary?.alumniDiscount && !showInputField && showIHaveCouponLink && !hasCouponApplied(item)" (click)="hideCouponLink()">I have a promo code</button>
                    <div class="chips" *ngIf="showInputField">
                      <!-- Form field for entering a new promo code -->
                      <mat-form-field class="coupon-chip">
                        <mat-label>Coupon</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Coupon code">
                          <input
                            class="coupon-input"
                            [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)"
                          >
                          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                        </mat-chip-grid>
                        <!-- hides the input field -->
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="hide()">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-form-field>
                    </div>
                    <!-- Removes the coupon -->
                    <div *ngFor="let coupon of coupons">
                      <mat-chip *ngIf="coupon.priceId === item.priceId">
                        {{ coupon.name }}
                        <button matChipRemove (click)="remove(coupon)">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                    </div>

                    <div *ngIf="isValidCoupon && hasCouponApplied(item)" class="content-panel-item">
                      <span>Coupon</span>
                      <span class="price">- ${{ couponAmount }}</span>
                    </div>

                    <div  class="content-panel-item" style="flex-direction: column">
                      <div class="fee-items-holder">
                      <span>Subtotal</span>
                      <span class="price">${{ isValidCoupon && hasCouponApplied(item) ? upfrontAmountAfterCoupon  : reviewData?.receipt?.paymentSummary?.subTotal }}</span>
                      </div>
                      <i class="excluding-fee">excluding fees</i>
                    </div>
                  }
                  @else{
                    <!--
                    <div class="content-panel-item coupon-section">
                      <div>
                        <span>Base tuition</span>
                        <span class="price">${{reviewData?.hasAgreement ? reviewData?.receipt?.installmentSummary?.fullPrice : reviewData?.receipt?.installmentSummary?.basePrice }}</span>
                      </div>

                    </div> -->
                    <div class="content-panel-item" *ngIf="reviewData?.hasAgreement">
                      <span>Total tuition</span>
                      <span class="price">${{reviewData?.receipt?.installmentSummary?.fullPrice}}</span>
                    </div>
                    <div class="content-panel-item" *ngIf="reviewData?.hasAgreement">
                      <span>T1 tuition</span>
                      <span class="price">${{reviewData?.receipt?.installmentSummary?.basePrice}}</span>
                    </div>
                <!--    <div class="content-panel-item" *ngIf="reviewData?.receipt?.installmentSummary?.planDiscount">
                      <span>{{reviewData?.title}} savings</span>
                      <span class="price">-${{reviewData?.receipt?.installmentSummary?.planDiscount}}</span>
                    </div>-->
                    <div class="content-panel-item" *ngIf="reviewData?.receipt?.installmentSummary?.discountedPrice">
                      <span>Base Tuition</span>
                      <span class="price">${{reviewData?.receipt?.installmentSummary?.fullPrice}}</span>
                    </div>
                    <div class="content-panel-item" *ngIf="reviewData?.receipt?.installmentSummary?.planDiscount">
                      <span>Savings</span>
                      <span class="price">-${{reviewData?.receipt?.installmentSummary?.planDiscount}}</span>
                    </div>
                    <div class="content-panel-item" *ngIf="reviewData?.receipt?.installmentSummary?.discountedPrice">
                      <span>Tuition</span>
                      <span class="price">${{reviewData?.receipt?.installmentSummary?.discountedPrice}}</span>
                    </div>
                    <div class="content-panel-item">
                      <span>Installments</span>
                      <span class="price">{{reviewData?.receipt?.installmentSummary?.numberOfInstallments}}</span>
                    </div>
                    <div class="content-panel-item" >
                      <span>Monthly payment</span>
                      <span class="price">${{reviewData?.receipt?.installmentSummary?.installmentPrice}}</span>
                    </div>
                    <span class="sub-item-panel">Due Today</span>
                    <div class="content-panel-item" style="flex-direction: column" >
                      <div class="fee-items-holder">
                        <span>First month's pay</span>
                        <span class="price">${{reviewData?.receipt?.installmentSummary?.total}}</span>
                      </div>
                      <i *ngIf=" !hasCouponApplied(item)" class="excluding-fee">excluding fees</i>
                    </div>
                    <div class="content-panel-item" *ngIf="reviewData?.hasAgreement">
                      <span style="font-size: 14px; color: #fecb78">The remaining $6,700 for T2 will be paid after placement, with monthly payments according to the agreed terms.</span>
                    </div>
                    <!-- Button to show the promo code input field -->
                    <button class="link-button" *ngIf=" !showInputField && showIHaveCouponLink && !hasCouponApplied(item)" (click)="hideCouponLink()">I have a promo code</button>
                    <!-- Container for promo code input and chips -->
                    <div class="chips" *ngIf="showInputField">
                      <!-- Form field for entering a new promo code -->
                      <mat-form-field class="coupon-chip">
                        <mat-label>Coupon</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Coupon code">
                          <input
                            class="coupon-input"
                            [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)"
                          >
                          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                        </mat-chip-grid>
                        <!-- hides the input field -->
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="hide()">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-form-field>
                      </div>
                    <!-- Removes the coupon -->
                    <div *ngFor="let coupon of coupons">
                      <mat-chip *ngIf="coupon.priceId === item.priceId">
                        {{ coupon.name}}
                        <button matChipRemove (click)="remove(coupon)">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                    </div>

                    <div *ngIf="isValidCoupon && hasCouponApplied(item)" class="content-panel-item">
                      <span>Coupon</span>
                      <span class="price">- ${{ couponAmount }}</span>
                    </div>

                    <div *ngIf="isValidCoupon && hasCouponApplied(item)" class="content-panel-item" style="display: flex; flex-direction: column">
                      <div style="display: flex; justify-content: space-between"><span>You pay today</span>
                      <span class="price">${{ installmentAmountAfterCoupon }}</span>
                      </div>
                      <i class="excluding-fee">excluding fees</i>
                    </div>

                  }
                </div>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
        <div class="action-buttons">
          <span class="back-button" (click)="backStep()"><u>Back</u></span>
          <button class=" next-button" [ngClass]="selectedItem === null ? 'disabledButton' : '' " [disabled]="selectedItem === null" (click)="nextStep(3)">Next
          </button>

        </div>

        <div class="right-footer">
          <hr>
          <p class="footer-text">{{'Need help? Contact us at enrollment@cydeo.com '}} Phone: 571-640-9191 <br>Schedule a call: https://info.cydeo.com/enrollment-consultation</p>
        </div>
      </div>

      <div class="step3" [ngClass]="step === 3 ? 'show-step' : 'hide-step'">
        <!-- <app-complete-purchase [reviewData]="reviewData" [customerData]="customerData"></app-complete-purchase>-->
        <div class="payment-plan" [ngClass]="stripeLoaded ? 'show-step' : 'hide-step'">
          @if(step === 3){
            <div class="pay-element">
              <!--<payment-element-form (onPaymentSuccess)="onPaymentSuccess()" ></payment-element-form>-->
              <app-payment-test (onPaymentChange)="onPaymentChange($event)"
                                (onPaymentDone)="onPaymentDone($event)"
                                [payment]="paymentData"
                                [makePayment]="makePayment" ></app-payment-test>
            </div>
            @if(paymentSummary){
              <div class="panel-content-holder">
                <div class="panel-content-payment">
                  @if (paymentSummary?.paymentType === 'one_time'){
                    <div class="content-panel-item">
                        <span>Tuition</span>
                        <span class="price">${{paymentSummary?.paymentSummary?.basePrice}}</span>
                    </div>
                    <div class="content-panel-item" *ngIf="paymentSummary?.paymentSummary?.oneTimeDiscount > 0 && !paymentSummary?.paymentSummary?.alumniDiscount">
                      <span>Upfront savings</span>
                      <span class="price">- ${{paymentSummary?.paymentSummary?.oneTimeDiscount}}</span>
                    </div>
                    <div class="content-panel-item" *ngIf="paymentSummary?.paymentSummary?.alumniDiscount ">
                      <span>Alumni savings</span>
                      <span class="price">- ${{paymentSummary?.paymentSummary?.alumniDiscount}}</span>
                    </div>
                    <div class="content-panel-item" *ngIf="coupons.length > 0">
                      <span>Coupon saving</span>
                      <span class="price">- ${{paymentSummary?.paymentSummary?.couponDiscount}}</span>
                    </div>
                    <div class="content-panel-item" >
                      <span>Subtotal</span>
                      <span class="price">${{paymentSummary?.paymentSummary?.subTotal}}</span>
                    </div>
                    <div class="content-panel-item">
                      <span>Processing fee <br> <span *ngIf=" paymentSummary?.paymentSummary?.fee == 0" class="refundable-text"> No fees</span></span>
                      <span class="price">${{paymentSummary?.paymentSummary?.fee}}</span>
                    </div>
                    <hr style="margin: 0;">
                    <div class="content-panel-item">
                      <span>Total</span>
                      <span class="price">${{paymentSummary?.paymentSummary?.total}}</span>
                    </div>

                  }
                  @else{
                    <div class="content-panel-item ">
                      <span>Tuition</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.discountedPrice ? paymentSummary?.installmentSummary?.discountedPrice : paymentSummary?.installmentSummary?.basePrice }}</span>
                    </div>
                    <div class="content-panel-item">
                      <span>Installments</span>
                      <span class="price">{{paymentSummary?.installmentSummary?.numberOfInstallments}}</span>
                    </div>
                    <div class="content-panel-item ">
                      <span>Monthly payment</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.installmentPrice}}</span>
                    </div>
                    <div class="content-panel-item " *ngIf="coupons.length > 0">
                      <span>Coupon</span>
                      <span class="price">- ${{paymentSummary?.installmentSummary?.couponDiscount}}</span>
                    </div>
                    <div class="content-panel-item">
                      <span style="font-size: 18px; font-weight: 200">Due Today</span>
                      <span class="price"></span>
                    </div>
                    <div class="content-panel-item" >
                      <span>First month's pay</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.subTotal}}</span>
                    </div>
                    <div class="content-panel-item" >
                      <span>Processing fee <br> <span *ngIf="programData.productType === 'ENROLLMENT' && paymentSummary?.installmentSummary?.fee == 0" class="refundable-text"> No fees</span></span>
                      <span class="price">${{paymentSummary?.installmentSummary?.fee}}</span>
                    </div>
                    <hr style="margin: 0;">
                    <div class="content-panel-item">
                      <span>Total</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.total}}</span>
                    </div>
                  }


                </div>
           <!--     <div class="form-check terms-conditions" *ngIf="reviewData?.hasAgreement">
                  <input class="form-check-input" type="checkbox" id="defaultCheck3" [(ngModel)]="plansAgreement">
                  <label class="form-check-label promotion-label" for="defaultCheck3">
                    I acknowledge and agree that the T1 tuition price is as specified in the terms and conditions. Furthermore, I understand that the T2 tuition price remains outstanding and will become payable upon full payment of the T1 tuition, in accordance with the <span class="privacy-checkbox"> <u (click)="openTermsConditions()">Terms and Conditions.</u>.</span>
                  </label>
                </div>-->
                <div class="form-check terms-conditions" *ngIf="programData.productType !== 'ENROLLMENT'">
                  <input [disabled]="isPaying" class="form-check-input" type="checkbox" id="defaultCheck2" [(ngModel)]="termsChecked">
                  <label class="form-check-label promotion-label" for="defaultCheck2">
                    I have read and agree to the <span class="privacy-checkbox"> <u (click)="openTermsConditions()">Terms and Conditions</u>.</span>
                  </label>
                </div>
                <div class="form-check terms-conditions" *ngIf="programData.productType === 'ENROLLMENT'">
                  <label class="form-check-label promotion-label">
                   <span style="cursor: pointer" *ngIf="!termsChecked" (click)="openIframe()"><b><u>Click here</u> to sign the contract</b></span>
                   <span style="cursor: pointer" *ngIf="termsChecked"><b>You have completed signing the agreement</b></span>
                  </label>
                </div>
                <div class="action-buttons">
                  <span class="back-button" (click)="backStep()"><u>Back</u></span>
                  @if(reviewData.hasAgreement){
                    <button type="button" mat-button class="next-button" [disabled]=" !termsChecked || isPaying" [ngClass]="isPaying ? 'disabledButton spinner' : ''" (click)="pay()">{{isPaying ? ' &nbsp; &nbsp; ' : 'Pay'}}</button>
                  } @else {
                    <button type="button" mat-button class="next-button" [disabled]="!termsChecked || isPaying" [ngClass]="isPaying ? 'disabledButton spinner' : ''" (click)="pay()">{{isPaying ? ' &nbsp; &nbsp; ' : 'Pay'}}</button>
                  }
                </div>
                <div class="right-footer">
                  <hr>
                  <p class="footer-text">{{'Need help? Contact us at enrollment@cydeo.com '}} Phone: 571-640-9191 <br>Schedule a call: https://info.cydeo.com/enrollment-consultation</p>
                </div>
              </div>
            }

              }
        </div>
        <div class="show-loader" [ngClass]="!stripeLoaded ? 'show-step' : 'hide-step'">
          <mat-spinner></mat-spinner>
        </div>

      </div>
      <div class="step4" [ngClass]="step === 4 ? 'show-step' : 'hide-step'">
        <div class="col-10">
          <div class="payment-confirmation">
            <p class="confirmation-title">Payments confirmation <img src="assets/images/Vector.svg"></p>
            <p style="color: #000;">You successfully signed up for <span class="purplish">{{programData?.lmsProgramName}}</span>. Your receipt will
              be send to <u>{{customerData?.email}}.</u> </p>
            <p style="color: #000;">You took the first step. Now let us show you the path.</p>
          </div>
          <div class="onboarding">
            <p class="onboarding-title">Onboarding <img src="assets/images/Login.svg"></p>
            <p> You will get a verification email from us. Once you are verified, you will be asked to set up your
              password. Then you will be able access your learning content.</p>
            <p>You will get another email with further instructions on how to join other learning applications used in
              the course.</p>
            <br>
            <p class="purplish">Keep an eye on your inbox.</p>
          </div>
          <div class="support">
            <p class="support-title">Support <img src="assets/images/Agent.svg"></p>
            <p> We are here to help. If you have any questions about your sign up process, reach out to us at<span
              class="support-email"> {{'enrollment@cydeo.com'}}</span>.</p>
            <p>For technical issues related to accessing your learning content, contact our technical support team
              at<span class="support-email"> {{'support@cydeo.com'}}</span>.</p>
            <br>
            <p>You can now close this page.</p>
          </div>
        </div>
        <div class="right-footer">
          <hr>
          <p class="footer-text">{{'Need help? Contact us at enrollment@cydeo.com '}} Phone: 571-640-9191 <br>Schedule a call: https://info.cydeo.com/enrollment-consultation</p>
        </div>
      </div>
    </div>
  </div>
</div>
